import React from "react"
import "../styles/memberships.scss"
import logo from "../images/logo.svg"
import memberImage from "../images/member.jpg" 
import barcode from "../images/barcode.svg"
import qrcode from "../images/qr-code.svg"

class Members extends React.Component {

    fileSelectedHandler = event => {
        var image = document.getElementById('output');
        image.src = URL.createObjectURL(event.target.files[0]);
    }

    render() {
        return (
            <section id="member-card-section">
                <div className="membercard">      
                    <div className="inner">
                        <div className="top">
                            <div className="img-upload">
                                <img src={memberImage} alt="logo"/>
                            </div> 
                            <div className="barcode">
                                <img src={barcode} alt="barcode" />
                            </div>
                            <div className="logo">
                                <img src={logo} alt="logo" />
                            </div> 
                        </div>
                        <div className="bottom">
                            <div className="fields">
                                <div className="field">
                                    <p className="text">Name:</p>
                                    <p className="text">John Citizen</p>
                                </div>
                                <div className="field">
                                    <p className="text">Number:</p>
                                    <p className="text">88933</p>
                                </div>
                                <p className="tagline">together we can stop the melt</p>
                            </div>
                            <div className="qr">
                                <img src={qrcode} alt="qr code" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Members
