import React from "react"
import { navigate } from "@reach/router"
import uploadicon from "../images/upload-icon-01.svg"
import "../styles/application.scss"

export default class Application extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
            firstName: "",
            memberPhoto: uploadicon,
        };
    }
   
    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleFileUpload = event => {
        const file = event.target.files[0];

        this.getBase64(file).then((result) => {     
            this.setState({memberPhoto: result});
        })
    }

    getBase64(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result)
            };
            reader.onerror = function (error) {
            console.log('Error: ', error);
            };
        })
    }

    handleSubmit = event => {
        event.preventDefault()
        const firstName = this.state.firstName;
        const memberPhoto = this.state.memberPhoto;
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('memberPhoto', memberPhoto);
        navigate(`/member`)
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label className="section-heading">
                    <input
                    type="text"
                    className="text input"
                    name="firstName"
                    maxLength="20"
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                    placeholder="Your Name"
                    />
                </label>
                <div className="file-input">
                    <label htmlFor="files">
                        <img id="profile-image" src={this.state.memberPhoto} alt="Profile" />
                    </label>         
                    <input
                    id="files" 
                    type="file"
                    onChange={this.handleFileUpload}
                    />
                </div>
                <button type="submit" className="ils-btn">Download</button>
            </form>      
        )
    }
}